import { ReferenceMapping } from '@wap-client/services'
import moment from 'moment'
import 'moment/locale/tr'

export const getThisWeek = (type: 'start' | 'end') => {
  const today = moment()

  if (type === 'start') {
    const startOfWeek = today
      .clone()
      .startOf('week')
      .add(1, 'days')
      .toISOString()
    return startOfWeek
  } else {
    const endOfWeek = today.clone().endOf('week').toISOString()
    return endOfWeek
  }
}

export const getThisMonth = (type: 'start' | 'end') => {
  const today = moment()
  if (type === 'start') {
    let month = today.startOf('month').add(1, 'day')
    return month.toISOString()
  } else {
    let month = today.endOf('month')
    return month.toISOString()
  }
}

export const EVENTS_MAPPING: ReferenceMapping[] = [
  {
    name: 'baslangicTarihi',
    path: 'baslangicTarihi',
    type: 'string',
  },
  {
    name: 'baslik',
    path: 'baslik',
    type: 'string',
  },
  {
    name: 'bitisTarihi',
    path: 'bitisTarihi',
    type: 'string',
  },
  {
    name: 'festival',
    path: 'festival',
    type: 'static',
  },
  {
    name: 'galeri',
    path: 'galeri',
    type: 'image',
  },
  {
    name: 'gorsel',
    path: 'gorsel',
    type: 'image',
  },
  {
    name: 'id',
    path: 'id',
    type: 'string',
  },
  {
    name: 'kategori',
    path: 'kategori',
    type: 'static',
  },
  {
    name: 'katilimcilar',
    path: 'katilimcilar',
    type: 'static',
  },
  {
    name: 'mekan',
    path: 'mekan',
    type: 'static',
  },
  {
    path: 'page',
    name: 'page',
    type: 'static',
  },
  {
    name: 'seanslar',
    path: 'seanslar',
    type: 'static',
  },
  {
    name: 'ucretsiz',
    path: 'ucretsiz',
    type: 'static',
  },
  {
    name: 'oneCikanEtkinlik',
    path: 'oneCikanEtkinlik',
    type: 'static',
  },
]

export const FILTER_FESTIVAL_MAPPING: ReferenceMapping[] = [
  {
    name: 'baslik',
    path: 'baslik',
    type: 'string',
  },
  {
    name: 'id',
    path: 'id',
    type: 'string',
  },
  {
    name: 'bitisTarihi',
    path: 'bitisTarihi',
    type: 'string',
  },
  {
    name: 'baslangicTarihi',
    path: 'baslangicTarihi',
    type: 'string',
  },
]

export const FILTER_PLACE_MAPPING: ReferenceMapping[] = [
  {
    name: 'baslik',
    path: 'baslik',
    type: 'string',
  },
  {
    name: 'id',
    path: 'id',
    type: 'string',
  },
]

export const FILTER_CATEGORY_MAPPING: ReferenceMapping[] = [
  {
    name: 'baslik',
    path: 'baslik',
    type: 'string',
  },
  {
    name: 'id',
    path: 'id',
    type: 'string',
  },
]

export const dateList = [
  {
    baslik: 'Bugün',
    id: '0',
    startDate: moment().toISOString(),
    endDate: moment().toISOString(),
  },
  {
    baslik: 'Yarın',
    id: '1',
    startDate: moment().add(1, 'day').toISOString(),
    endDate: moment().add(1, 'day').toISOString(),
  },
  {
    baslik: 'Bu Hafta',
    id: '2',
    startDate: getThisWeek('start'),
    endDate: getThisWeek('end'),
  },
  {
    baslik: 'Bu Ay',
    id: '3',
    startDate: getThisMonth('start'),
    endDate: getThisMonth('end'),
  },
  // {
  //   baslik: 'Arşiv',
  //   id: '4',
  //   startDate: moment().toISOString(),
  //   endDate: moment().toISOString(),
  // },
]

export enum QUERY_KEY {
  festivals = 'festivalId',
  categories = 'categoryId',
  places = 'placeId',
  dates = 'dates',
  startDate = 'startDate',
  endDate = 'endDate',
  title = 'title',
}

export const queryDatesConvert = (dateId: string) => {
  switch (dateId) {
    case '0':
      return 'today'
    case '1':
      return 'tomorrow'
    case '2':
      return 'week'
    case '3':
      return 'month'
    case '4':
      return 'archive'
    case '999':
      return 'picker'
    default:
      return ''
  }
}

export const GLOBAL_EVENTS_MAPPING: ReferenceMapping[] = [
  {
    name: 'id',
    path: 'id',
    type: 'string',
  },
  {
    name: 'buyTicket',
    path: 'biletSatisAdresi',
    type: 'link',
  },
  {
    name: 'dateRange',
    path: 'tarihAraligi',
    type: 'static',
  },
  {
    name: 'festival',
    path: 'festival',
    type: 'static',
  },
  {
    name: 'isFree',
    path: 'ucretsiz',
    type: 'static',
  },
  {
    name: 'page',
    path: 'page',
    type: 'static',
  },
  {
    name: 'place',
    path: 'mekan',
    type: 'static',
    children: [
      {
        name: 'baslik',
        path: 'baslik',
        type: 'static',
      },
      {
        name: 'adres',
        path: 'adres',
        type: 'static',
      },
      {
        name: 'id',
        path: 'id',
        type: 'static',
      },
      {
        name: 'lat',
        path: 'lat',
        type: 'static',
      },
      {
        name: 'lng',
        path: 'lng',
        type: 'static',
      },
      {
        name: 'yolTarifi',
        path: 'yolTarifi',
        type: 'link',
      },
    ],
  },
]
