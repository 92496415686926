import { queryService } from '@wap-client/services'
import { AppContextProps } from '@wap-client/core'
import {
  EVENTS_MAPPING,
  FILTER_FESTIVAL_MAPPING,
  FILTER_PLACE_MAPPING,
  FILTER_CATEGORY_MAPPING,
} from '@/components/widgets/events/costants'
import { IEvent } from '@/components/widgets/events/event-card/types'
import {
  IPlace,
  IFestival,
  ICategory,
} from '@/components/widgets/events/event-filter/types'

export const getEvents = async (app: AppContextProps, query?: string) => {
  try {
    const response = await queryService.run<Array<IEvent>>(
      app.environment,
      {
        name: 'get-app-kty-etkinlikler',
        language: app.language,
        query: query,
      },
      EVENTS_MAPPING
    )

    if (response) {
      return response.data
    }
  } catch (err) {
    console.log(err)
  }
}

export const getGlobalEvents = async (app: AppContextProps, query?: string) => {
  try {
    const response = await queryService.run<Array<IEvent>>(
      app.environment,
      {
        name: 'get-app-kty-uluslararasi-etkinlikler',
        language: app.language,
        query: query,
      },
      EVENTS_MAPPING
    )

    if (response) {
      return response.data
    }
  } catch (err) {
    console.log(err)
  }
}

export const getEvent = async (app: AppContextProps, query?: string) => {
  try {
    const response = await queryService.run<Array<IEvent>>(
      app.environment,
      {
        name: 'kty-etkinlikler-get-veri-sorgusu-single',
        language: app.language,
        query: query,
      },
      EVENTS_MAPPING
    )

    if (response) {
      return response.data
    }
  } catch (err) {
    console.log(err)
  }
}

export const getFestivals = async (
  app: AppContextProps,
  query?: { name: string; value: string }
) => {
  const params = new URLSearchParams()

  if (query) {
    params.append(query.name, query.value)
  }

  try {
    const response = await queryService.run<Array<IFestival>>(
      app.environment,
      {
        name: 'kty-festivaller-get-v2-veri-sorgusu',
        language: app.language,
        query: params.toString(),
      },
      FILTER_FESTIVAL_MAPPING
    )

    if (response) {
      return response.data
    }
  } catch (err) {
    console.log(err)
  }
}

export const getPlaces = async (
  app: AppContextProps,
  query?: { name: string; value: string }
) => {
  const params = new URLSearchParams()

  if (query) {
    params.append(query.name, query.value)
  }

  try {
    const response = await queryService.run<Array<IPlace>>(
      app.environment,
      {
        name: 'get-app-kty-mekanlar',
        language: app.language,
        query: params.toString(),
      },
      FILTER_PLACE_MAPPING
    )

    if (response) {
      return response.data
    }
  } catch (err) {
    console.log(err)
  }
}

export const getCategories = async (
  app: AppContextProps,
  query?: { name: string; value: string }
) => {
  const params = new URLSearchParams()

  if (query) {
    params.append(query.name, query.value)
  }

  try {
    const response = await queryService.run<Array<ICategory>>(
      app.environment,
      {
        name: 'kty-festivaller-kategori-get-veri-sorgusu',
        language: app.language,
        query: params.toString(),
      },
      FILTER_CATEGORY_MAPPING
    )

    if (response) {
      return response.data
    }
  } catch (err) {
    console.log(err)
  }
}

export const getFestivalsV2 = async (
  app: AppContextProps,
  query?: { name: string; value: string }
) => {
  const params = new URLSearchParams()

  if (query) {
    params.append(query.name, query.value)
  }

  try {
    const response = await queryService.run<Array<IFestival>>(app.environment, {
      name: 'kty-festivaller-get-v2-veri-sorgusu',
      language: app.language,
      query: params.toString(),
    })

    if (response) {
      return response.data
    }
  } catch (err) {
    console.log(err)
  }
}
